import { v4 } from 'uuid';

import TYPES from '@/types';

// Application
import { UpdateCustomerDocumentFileCommand } from '@/modules/onboarding/customer-document/application/services/file/update';
import SizeTranslator from '@/modules/common/application/services/file/size-translator';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Functions from '@/modules/shared/domain/utils/functions';

export default class UpdateProofOfAddressViewModel {
  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier;

  @Inject(TYPES.ONBOARDING_UPDATE_CUSTOMER_DOCUMENT_FILE_COMMAND)
  private readonly update_customer_document_file_command!: UpdateCustomerDocumentFileCommand;

  @Inject(TYPES.UTIL_FUNCTIONS)
  private readonly functions!: Functions;

  private readonly view!: any;

  loading = false;

  inputs: { proof_of_address?: File } = {
    proof_of_address: undefined,
  };

  inputs_config = {
    proof_of_address: {
      accept: 'image/jpg,image/jpeg,image/png',
      size_limit: 1000 * 1024 * 4,
    },
  };

  proof_of_address_updated = false;

  get can_continue() {
    return !!this.inputs.proof_of_address;
  }

  public constructor(view: any) {
    this.view = view;
  }

  selectFile = ({ target }: any) => {
    const selected_file = target.files[0];
    const valid_file_size = (
      selected_file.size <= this.inputs_config.proof_of_address.size_limit
    );

    if (valid_file_size) {
      this.inputs.proof_of_address = selected_file;
    } else {
      this.notifier.showErrorNotification(
        `El comprobante de domicilio no puede pesar más de ${SizeTranslator.bytesToMB(
          this.inputs_config.proof_of_address.size_limit,
        )} Mb`,
      );
    }
  };

  updateCustomerDocumentFile = async () => {
    try {
      this.loading = true;
      const file_data = await this.functions.convert_file_to_base_64(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.inputs.proof_of_address!,
      );

      await this.update_customer_document_file_command.execute({
        document_type: {
          name: 'proof-of-address',
        },
        token: this.view.$route.query.token,
        file: {
          name: v4(),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          mime_type: this.inputs.proof_of_address!.type,
          file_data: file_data as string,
        },
      });

      this.proof_of_address_updated = true;
    } catch {
      this.notifier.showErrorNotification('Hubo un error al subir tu comprobante de domicilio');
    } finally {
      this.loading = false;
    }
  };
}
