































































































import { Vue, Component } from 'vue-property-decorator';
import Card from '@/vue-app/components/onboarding/Card.vue';
import PreviewDocument from '@/vue-app/components/documents/preview-document.vue';
import CustomerDocumentSuccessfullyUpdated
  from '@/vue-app/components/onboarding/customer-documents/customer-document-successfully-updated.vue';
import UpdateProofOfAddressViewModel
  from '@/vue-app/view-models/pages/update-proof-of-address-view-model';

@Component({
  name: 'UpdateProofOfAddress',
  components: {
    Card,
    PreviewDocument,
    CustomerDocumentSuccessfullyUpdated,
  },
})
export default class UpdateProofOfAddress extends Vue {
  readonly update_proof_of_address_view_model = Vue.observable(
    new UpdateProofOfAddressViewModel(this),
  );

  created() {
    this.$store.dispatch('layout/toggleGoToSiteOnHeader', false);
  }
}
