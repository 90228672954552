

































import { Vue, Component } from 'vue-property-decorator';
import CustomerDocumentSuccessfullyUpdatedViewModel from '@/vue-app/view-models/components/on-boarding/customer-documents/customer-document-successfully-updated-view-model';

@Component({ name: 'CustomerDocumentSuccessfullyUpdated' })
export default class CustomerDocumentSuccessfullyUpdated extends Vue {
  // eslint-disable-next-line max-len
  customer_document_successfully_updated_view_model = new CustomerDocumentSuccessfullyUpdatedViewModel();
}
